import React, { useEffect } from "react";
import { Container, Row, Col, Card } from "react-bootstrap";
import styled from "styled-components";

const CompanyInfoContainer = styled(Container)`
  background-color: #12121a;
  padding: 60px 0;
  color: #6128af;
`;

const SectionTitle = styled.h1`
  font-size: 3em;
  font-weight: bold;
  color: #b8a2ec; /* XM Mobile Lab color theme */
  text-align: center;
  background-color: #12121a;
  margin-bottom: 20px;
`;

const SectionText = styled.p`
  font-size: 1.2em;
  line-height: 1.8;
  color: #a5b9ce;
  text-align: center;
`;

const StyledCard = styled(Card)`
  margin: 20px 0;
  background-color: #24163C;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
`;

const CardTitle = styled.h3`
  font-size: 1.5em;
  font-weight: bold;
  color: whitesmoke;
`;

const CardText = styled.p`
  font-size: 1.1em;
  color: #d9c7c7;
  line-height: 1.6;
`;

function Home2() {
    useEffect(() => {
        document.title = "XM MOBILE LAB";
    }, []);

    return (
        <CompanyInfoContainer fluid className="home-about-section home-header" id="about" >
            <Container fluid className="home-about-section">
                <Row>
                    <Col md={12} >
                        <SectionTitle>About XM MOBILE LAB</SectionTitle>
                        <SectionText>
                            XM MOBILE LAB is a cutting-edge technology company specializing in
                            delivering innovative software solutions and cloud services. Our
                            mission is to empower businesses with high-performance mobile and
                            web applications, tailored to meet modern market demands.
                        </SectionText>
                        <SectionText>
                            With a strong focus on scalability, security, and user experience,
                            we leverage the latest technologies to create products that drive
                            digital transformation for our clients.
                        </SectionText>
                    </Col>
                </Row>

                <Row>
                    <Col md={6}>
                        <StyledCard>
                            <Card.Body>
                                <CardTitle>Software Solutions</CardTitle>
                                <CardText>
                                    We specialize in crafting tailored software solutions, from
                                    mobile apps to full-stack web platforms. Whether you're looking
                                    for enterprise-grade systems or consumer-facing products, we
                                    deliver robust, scalable solutions to fit your needs.
                                </CardText>
                            </Card.Body>
                        </StyledCard>
                    </Col>
                    <Col md={6}>
                        <StyledCard>
                            <Card.Body>
                                <CardTitle>Cloud Services</CardTitle>
                                <CardText>
                                    Our cloud services offer scalable, secure, and cost-effective
                                    solutions for businesses of all sizes. From cloud migrations
                                    to DevOps implementation, we provide a complete suite of cloud
                                    services to streamline your operations.
                                </CardText>
                            </Card.Body>
                        </StyledCard>
                    </Col>
                </Row>

                <Row>
                    <Col md={6}>
                        <StyledCard>
                            <Card.Body>
                                <CardTitle>Innovation & Digital Transformation</CardTitle>
                                <CardText>
                                    At XM MOBILE LAB, innovation is at the core of what we do. We
                                    focus on digital transformation strategies that enable businesses
                                    to compete in an increasingly digital landscape.
                                </CardText>
                            </Card.Body>
                        </StyledCard>
                    </Col>
                    <Col md={6}>
                        <StyledCard>
                            <Card.Body>
                                <CardTitle>Consulting & Support</CardTitle>
                                <CardText>
                                    Our expert consulting services help businesses navigate complex
                                    tech challenges. We provide ongoing support to ensure that your
                                    software and cloud solutions are always optimized for performance.
                                </CardText>
                            </Card.Body>
                        </StyledCard>
                    </Col>
                </Row>
            </Container>
        </CompanyInfoContainer>
    );
}

export default Home2;
