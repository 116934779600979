import React, {useEffect} from "react";
import { Container } from "react-bootstrap";
import Footer from "../Footer";
import Navbar from "react-bootstrap/Navbar";
import NavBar from "../Navbar";

function PrivacyPolicy() {

    useEffect(() => {
        document.title = "Privacy Policy - JetPro VPN";
    }, []);
    return (
        <Container fluid className="home-content p-4 ms-lg-12 me-lg-12 text-xs font-weight-light mx-auto" style={{ maxWidth: '1200px' }}>

            <div className={"mt-5 pt-5  "}>

                <NavBar/>
            </div>


            <h4>Privacy Policy</h4>
            <p>
                At XM MOBILE LAB PRIVATE LIMITED and its affiliates, we are committed to safeguarding your privacy. We adhere to strict data collection protocols, gathering only the information necessary to provide and enhance our services.
            </p>

            <p>
                This Privacy Policy outlines how we collect, use, and share your information in relation to your use of our services, and applications (collectively referred to as "JetPro VPN"). Please note that this Privacy Policy does not apply to data that you send or receive through third-party websites while using JetPro VPN.
            </p>

            <h5>1. Collection of Data</h5>
            <h5>1.1 Data Not Collected</h5>
            <p>JetPro VPN does not collect the following types of data:</p>
            <ul>
                <li><strong>Browsing History:</strong> We do not track the websites you visit or your browsing activities.</li>
                <li><strong>Content of Communications:</strong> We do not collect any content from your messages, emails, or other communications transmitted through the application.</li>
                <li><strong>Location Data:</strong> We do not collect any location data or require location permissions.</li>
                <li><strong>Sensitive Data:</strong> Our systems are designed to permanently erase sensitive data, and we do not collect such information.</li>
                <li><strong>Online Activities:</strong> We do not log your online activities, including:
                    <ul>
                        <li>(1) Browsing history;</li>
                        <li>(2) Traffic destination;</li>
                        <li>(3) Data content; and</li>
                        <li>(4) DNS queries.</li>
                    </ul>
                </li>
                <li><strong>Connection Logs:</strong> We do not collect connection logs, which include:
                    <ul>
                        <li>(1) Your IP address;</li>
                        <li>(2) Your outgoing VPN IP address;</li>
                        <li>(3) Connection timestamps; and</li>
                        <li>(4) Session duration.</li>
                    </ul>
                </li>
                <li><strong>No Identifiable Information:</strong> Since we do not collect activity or connection logs, we cannot ascertain:
                    <ul>
                        <li>(1) The websites you visit;</li>
                        <li>(2) The time you connected to JetPro VPN; and</li>
                        <li>(3) The original IP addresses of your device or the temporarily assigned network addresses.</li>
                    </ul>
                </li>
            </ul>

            <h5>1.2 Data Collection Practices</h5>
            <p>We minimize data collection to only what is essential for maintaining and improving the services offered by JetPro VPN.</p>

            <h5>1.2.1 Data Necessary for Maintaining Service Quality</h5>
            <p>To ensure optimal service delivery, we collect the following data related to your use of JetPro VPN:</p>
            <ul>
                <li><strong>Version Information:</strong> We collect data regarding the version of JetPro VPN you are using. This helps us quickly identify and resolve any technical issues you may encounter.</li>
                <li><strong>VPN Connection Data:</strong> We collect abstracted data concerning your usage of JetPro VPN, including connection success rates, the location of the VPN server you connected to, your country, and your internet service provider. This abstracted data does not allow for the identification of individual users.</li>
                <li><strong>Data Transfer Volume:</strong> We monitor the total amount of data transferred to ensure fair usage and prevent any single user from consuming excessive resources that could negatively impact others' experiences.</li>
                <li><strong>Anonymous Diagnostic Data:</strong> We collect anonymized data for operational purposes, including diagnostic information such as VPN connection failures, speed test results, and crash reports.</li>
            </ul>

            <h5>2. USE OF DATA</h5>

            <h6>2.1 System Diagnostics</h6>
            <p>
                We utilize data related to your usage of JetPro VPN to conduct essential internal operations aimed at maintaining and enhancing our services. This includes operational troubleshooting, as well as data analysis and activity trend analysis to identify and resolve issues proactively. By analyzing this data, we can improve the overall user experience and ensure the reliability and efficiency of JetPro VPN.
            </p>

            <h6>2.2 System Diagnostics</h6>
            <p>
                We utilize data related to your usage of JetPro VPN to perform essential internal operations that ensure the seamless functioning of our services. This encompasses operational troubleshooting, as well as conducting data analysis and activity trend analysis. By leveraging this information, we can identify and address potential issues proactively, ultimately enhancing the overall performance and reliability of JetPro VPN.
            </p>

            <h6>2.3 Research and Development</h6>
            <p>
                We utilize your data to gain insights into your usage of JetPro VPN, enabling us to develop new products, services, features, and functionalities tailored to enhance your experience. This analytical approach helps us stay attuned to your needs and continuously improve our offerings.
            </p>

            <h6>2.4 Service Providers</h6>
            <p>
                We may engage data from service providers and third-party vendors to support and promote the services offered by JetPro VPN. This collaboration allows us to enhance our service delivery, ensuring that we meet your expectations while maintaining the highest standards of quality and security.
            </p>

            <h6>2.4 Data Security</h6>
            <p>
                Your data security is paramount to us. We have strict controls and Secure Core servers to ensure a high level of protection. Your online activities remain confidential, and we do not log VPN session data.            </p>
            <h6>3. RIGHT TO BE FORGOTTEN</h6>
            <p>
                You have the right to request the deletion of your data under the following circumstances:
            </p>
            <ul>
                <li>(1) Your data is no longer necessary for the purposes for which it was originally collected or processed;</li>
                <li>(2) You withdraw your consent for us to hold your data, and we are relying on your consent as the lawful basis for processing;</li>
                <li>(3) You object to our processing of your data, and there is no overriding legitimate interest that permits us to continue such processing; or</li>
                <li>(4) We are processing your data for direct marketing purposes, and you object to this processing.</li>
            </ul>
            <h5>4. THIRD PARTY SERVICES</h5>
            <p>
                While using JetPro VPN, you may encounter links to third-party services. We want to make it clear that we are not responsible for the privacy policies and practices of these third-party services. We encourage you to review their privacy policies carefully to understand how they collect, use, and protect your information.
            </p>
            <h5>5. ADVERTISING</h5>
            <p>
                To provide you with access to JetPro VPN, we collaborate with third-party advertising partners to offset the costs associated with our services. Importantly, we do not target advertisements based on your personal data, and we will not share or utilize your data in connection with third-party advertisers without your explicit prior consent.
            </p>
            <p>
                Our third-party advertising partners may set and access their own cookies, pixel tags, and similar technologies on our services. They may also collect or gain access to data about you, which could be gathered over time and across different online services.
            </p>
            <p>
                Please note that we offer access to servers at no cost to you through this collaboration, ensuring you can enjoy our services without incurring direct charges.
            </p>

            <h5>6. CHILDREN’S PRIVACY</h5>
            <p>
                We encourage parents and guardians to educate their children about privacy and the potential use of their information on the Internet. Children should never disclose their name, address, phone number, or any personal information without the prior permission of their parents or guardians.
            </p>
            <p>
                For the purposes of this policy, we define a child as any user under the age of 13, unless more stringent regulations apply in their state of residence.
            </p>
            <p>
                If we determine that any of our services are directed at users under the minimum legal age required in their country of residence, we reserve the right to restrict such users from accessing our services or providing information. Additionally, we may limit the collection of information from these users as described in further detail below.
            </p>
            <p>
                Users who do not meet the minimum legal age required in their state of residence are prohibited from submitting any personal information to JetPro VPN. Specifically, such users are restricted from:
            </p>
            <ul>
                <li>Displaying their real name or any personal information within JetPro VPN;</li>
                <li>Accessing chat features, leaderboards, forums, or social networking services (SNS) such as Facebook or Game Center;</li>
                <li>Subscribing to newsletters;</li>
                <li>Using any other features where users’ real names might be displayed.</li>
            </ul>
            <p>
                In regard to these users, we will prevent the collection and use of precise geolocation data and will only display contextual advertising (excluding any behavioral advertising).
            </p>
            <p>
                Children may be invited to participate in testing our websites and applications with the authorization of their parents or guardians. The physical presence of a parent or guardian may be required, depending on the regulations in the state where the testing occurs.
            </p>
            <p>
                Parents are encouraged to exercise their privacy rights for their children as outlined in this Privacy Policy.
            </p>
            <h3>7. ORDERS COMPELLING THE DISCLOSURE OF YOUR DATA</h3>
            <p>
                Our data collection practices are strictly limited. We only gather your device identification code and any other information you voluntarily provide when you contact us via email or interact with our website or the JetPro VPN application pages on Google Play or the App Store. We do not collect IP addresses, browsing history, traffic data, or DNS queries that could be used to identify you. Consequently, any potential disclosure of your data is minimal.
            </p>
            <p>
                We are dedicated to safeguarding your data. However, we may be required to disclose your data in compliance with a valid court order enforceable in Singapore, an order from law enforcement agencies, or when legally obligated to do so. In such JetPro VPN, we will ensure that the recipient of your data commits to protecting it to a standard that meets or exceeds the protections stipulated by Singapore’s Personal Data Protection Act (“PDPA”).
            </p>
            <h5>Contact Us</h5>
            <p>
                If you have any questions or concerns regarding your data and privacy, please feel free to reach out to us via email at <a href="mailto:support@xmmobilelab.com">support@xmmobilelab.com</a>. We are here to assist you.
            </p>

            <h6>Thank you for choosing JetPro VPN. We appreciate your trust in us to safeguard your privacy while you enjoy a secure and private online experience with our VPN service.</h6>
            {/*<div style={{ width: '100%' }}>*/}
            {/*    <Footer />*/}
            {/*</div>*/}
        </Container>
    );
}

export default PrivacyPolicy;
