import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";

function Footer() {
  let date = new Date();
  let year = date.getFullYear();

  return (
      <Container fluid className="footer">
        <Row className="text-center">
          <Col md={4} className="footer-copywright">
            <p>
              <Link to="/insta_terms" className="footer-link">
                Terms of Service
              </Link>
            </p>
            <p>
              <Link to="/insta_privacy" className="footer-link">
                Privacy Policy
              </Link>
            </p>

          </Col>
          <Col md={4} className="footer-links  justify-content-center">
            <ul className="list-unstyled">
              <h3>© {year} XM MOBILE LAB PRIVATE LIMITED</h3>

            </ul>
          </Col>
          <Col md={4} className="footer-copywright">

            <p>
              <strong>Email:</strong>{" "}
              <a href="mailto:contact@xmmmobilelab.com">contact@xmmmobilelab.com</a>
            </p>
            <p>
              <strong>Address:</strong> R-255 Sham Nagar Alampura Latur
            </p>
          </Col>
        </Row>
      </Container>
  );
}

export default Footer;
