import React, { useState, useEffect } from "react";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import Container from "react-bootstrap/Container";
import {AiOutlineInfoCircle} from "react-icons/ai";

function NavBar() {
    const [expand, updateExpanded] = useState(false);
    const [navColour, updateNavbar] = useState(false);

    function scrollHandler() {
        if (window.scrollY >= 20) {
            updateNavbar(true);
        } else {
            updateNavbar(false);
        }
    }

    // Add smooth scroll behavior
    const scrollToSection = (sectionId) => {
        const section = document.getElementById(sectionId);
        if (section) {
            section.scrollIntoView({ behavior: "smooth" });
        }
    };

    useEffect(() => {
        window.addEventListener("scroll", scrollHandler);
        return () => {
            window.removeEventListener("scroll", scrollHandler);
        };
    }, []);

    return (
        <Navbar
            expanded={expand}
            fixed="top"
            expand="md"
            className={navColour ? "sticky" : "navbar"}
        >
            <Container>
                <Navbar.Brand href="/" className="flex">
    <span className="navbar-brand-icon">
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            width="30" // Adjust width and height as needed
            height="30"
        >
            <defs>
                <linearGradient id="gradientStar" x1="0%" y1="0%" x2="100%" y2="100%">
                    <stop offset="0%" style={{ stopColor: "#8c45f5", stopOpacity: 1 }} />
                    <stop offset="100%" style={{ stopColor: "#7b95fe", stopOpacity: 1 }} />
                </linearGradient>
            </defs>
            <polygon
                points="12,2 15,10 24,10 17,15 19,23 12,18 5,23 7,15 0,10 9,10"
                fill="url(#gradientStar)"
            />
        </svg>
    </span>
                    <span className="navbar-brand-text mt-2">XM MOBILE LAB</span>
                </Navbar.Brand>

                <Navbar.Toggle
                    aria-controls="responsive-navbar-nav"
                    onClick={() => {
                        updateExpanded(expand ? false : "expanded");
                    }}
                >
                    <span></span>
                    <span></span>
                    <span></span>
                </Navbar.Toggle>
                <Navbar.Collapse id="responsive-navbar-nav">
                    <Nav className="ms-auto" defaultActiveKey="#home2">
                        <Nav.Item>
                            <Nav.Link
                                onClick={() => {
                                    scrollToSection("about"); // Use the ID of the section to scroll to
                                    updateExpanded(false); // Collapse the navbar after clicking
                                }}
                            >
                                <AiOutlineInfoCircle  style={{ marginBottom: "2px" }} /> About Us
                            </Nav.Link>
                        </Nav.Item>
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    );
}

export default NavBar;
