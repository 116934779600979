import React, { useState, useEffect } from "react";
import Preloader from "../src/components/Pre";
import Navbar from "./components/Navbar";
import Home from "./components/Home/Home";
import Footer from "./components/Footer";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
  useLocation
} from "react-router-dom";
import ScrollToTop from "./components/ScrollToTop";
import "./style.css";
import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import Contact from "./components/Contact";
import PrivacyPolicy from "./components/Privacy/PrivacyPolicy";
import Terms from "./components/Terms/Terms";
function App() {
  const [load, updateLoad] = useState(true);
  const location = useLocation(); // Get the current location

  useEffect(() => {
    const timer = setTimeout(() => {
      updateLoad(false);
    }, 1200);

    return () => clearTimeout(timer);
  }, []);

  // Array of routes where you want to hide the Navbar and Footer
  const hideNavAndFooter = ["/privacy", "/terms"]; // Add any additional routes here

  return (
      <>
        <Preloader load={load} />
        <div className="App" id={load ? "no-scroll" : "scroll"}>
          {!hideNavAndFooter.includes(location.pathname) && <Navbar />}
          <ScrollToTop />
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/insta_privacy" element={<PrivacyPolicy />} />
            <Route path="/insta_terms" element={<Terms />} />

            {/* Uncomment for future routes */}
            {/* <Route path="/project" element={<Projects />} /> */}
            {/* <Route path="/about" element={<About />} /> */}
            {/* <Route path="/resume" element={<Resume />} /> */}

            <Route path="*" element={<Navigate to="/" />} />
          </Routes>
          {!hideNavAndFooter.includes(location.pathname) && <Footer />}
        </div>
      </>
  );
}

export default function AppWrapper() {
  return (
      <Router>
        <App />
      </Router>
  );
}
