import React, { useState } from "react";
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import Footer from "./Footer";

function Contact() {
    const [formData, setFormData] = useState({
        name: "",
        email: "",
        message: "",
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        // You can implement your email sending logic here
        console.log("Form submitted:", formData);

        // Reset form after submission
        setFormData({
            name: "",
            email: "",
            message: "",
        });

        // Optionally, show a success message
        alert("Message sent successfully!");
    };

    return (
        <Container fluid className="contact-section" id="contact">
            <Row className="justify-content-center">
                <Col md={8} className="contact-header">
                    <h1 className="heading-name">Contact Us</h1>
                    <Form onSubmit={handleSubmit} style={{ padding: "20px" }}>
                        <Form.Group controlId="formName">
                            <Form.Label>Name</Form.Label>
                            <Form.Control
                                type="text"
                                name="name"
                                value={formData.name}
                                onChange={handleChange}
                                placeholder="Enter your name"
                                required
                            />
                        </Form.Group>

                        <Form.Group controlId="formEmail">
                            <Form.Label>Email</Form.Label>
                            <Form.Control
                                type="email"
                                name="email"
                                value={formData.email}
                                onChange={handleChange}
                                placeholder="Enter your email"
                                required
                            />
                        </Form.Group>

                        <Form.Group controlId="formMessage">
                            <Form.Label>Message</Form.Label>
                            <Form.Control
                                as="textarea"
                                name="message"
                                value={formData.message}
                                onChange={handleChange}
                                rows={4}
                                placeholder="Your message"
                                required
                            />
                        </Form.Group>

                        <Button variant="primary" type="submit">
                            Send Message
                        </Button>
                    </Form>
                </Col>
            </Row>
        </Container>
    );
}

export default Contact;

