import React from "react";
import Typewriter from "typewriter-effect";

function Type() {
  return (
    <Typewriter
      options={{
          strings: [
              "Software Developer",
              "Freelancer",
              "MERN Stack Developer",
              "Open Source Contributor",
              "Cloud Solutions Architect",
              "Web Application Developer",
              "Mobile App Developer",
              "UI/UX Designer",
              "DevOps Specialist",
              "Digital Transformation Consultant",
              "Software Engineer",
              "Full-Stack Developer",
              "Project Manager",
              "Technical Advisor",
              "IT Consultant",
              "Blockchain Developer",
              "Data Analyst",
              "Cybersecurity Specialist",
              "Tech Support Engineer",
          ],

          autoStart: true,
        loop: true,
        deleteSpeed: 50,
      }}
    />
  );
}

export default Type;
